import { EnumsExConnectType } from '@lp-lib/api-service-client/public';

import { type Organization } from '../../types';
import { getInitials } from '../../utils/string';
import { useOrgConnection } from './hooks/useOrgConnection';
import { OrgUtils } from './utils';

export function OrgConnection(props: {
  org: Organization;
  immutable?: boolean;
}): JSX.Element | null {
  const { org, immutable } = props;
  const { connection } = useOrgConnection({ orgId: org.id, immutable });

  if (!connection) return null;
  // don't show it for Teams, given there is no icon & name
  if (connection.type === EnumsExConnectType.ExConnectTypeTeams) return null;
  return (
    <div className='w-full flex items-center gap-2 px-1.5 py-1 bg-secondary rounded-xl'>
      {connection.icon ? (
        <img
          src={connection.icon}
          alt={connection.name}
          className='w-10 h-10 text-sms rounded-1.5lg'
        />
      ) : (
        <div className='w-10 h-10 rounded-1.5lg bg-lp-gray-003 text-white flex items-center justify-center text-xl font-bold'>
          {getInitials(connection.name)}
        </div>
      )}
      <div className='flex-1 truncate text-sms '>
        <div className='font-bold text-white'>{connection.name}</div>
        <div className='text-green-001'>
          {OrgUtils.GetConnTypeInfo(org, connection.type).label} Connected
        </div>
      </div>
    </div>
  );
}
